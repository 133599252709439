.ProfilePage {
    &-wrapper {
        padding: 0 12px 460px 12px;
        &-head {
            h4 {
                font-size: 18px;
                color: var(--white);
                margin-bottom: 12px;
            }
        }
        &-main {
            .ant-card {
                border-radius: 24px;
                border-top: 1px solid var(--violet);
            }
            &-refferals {
                margin-top: 16px;
                padding-bottom: 60px;

                h4 {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 16px;
                    span {
                        color: var(--violet);
                    }
                }

                &-bonus {
                    padding-right: 12px;
                }
            }
        }
    }

    .tonconnect-customize {
        margin: 16px 0;

        button {
            width: 90vw !important;
            height: 50px;
        }
    }

    .ant-list-item {
        border-top: 1px solid var(--violet);
        border-bottom: unset;
    }

    .dflex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }
}
