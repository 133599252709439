.EarnPage {
    min-height: 100vh;
    padding-bottom: 240px;

    &-wrapper {
        &-head {
            margin-bottom: 24px;

            h1 {
                font-size: 24px;
                font-weight: normal;
            }
        }
    }

    &-tasks {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        &-task {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #2e2e2e;
            margin: 6px 0;
            padding-bottom: 10px;

            .task-logo {
                width: 15%;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    font-size: 24px;
                }
            }

            .task-title {
                width: 65%;
                display: flex;
                gap: 4px;
                align-items: flex-start;
                flex-direction: column;

                span {
                    font-weight: bold;
                }

                p {
                    font-weight: 300;
                    color: var(--violet);
                }
            }

            .task-action {
                width: 100px;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    font-size: 24px;
                    color: var(--violet);
                }

                button {
                    border-radius: 16px;
                    height: 36px;
                    border: unset;
                    outline: unset;
                    width: 100px;
                }
            }
        }
    }
}
