@import './variables.scss';

button {
    font-family: $font;
    border: 0;
    cursor: pointer;
    outline: none;
    background-color: #fff;
    min-height: 3.2rem;
    padding: 0 0.5rem;
}

main {
    height: 100vh;
}

ul {
    list-style-type: none;
}
