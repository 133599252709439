.AnimateButton {
    display: flex;
    justify-content: center;

    &.fixed {
        position: fixed;
        bottom: 65px;
        // left: 0;
        // right: 0;
        .btn-custon-animate {
            width: 90%;
        }
    }
    a {
        text-decoration: none;
        color: var(--white);
    }
    .btn-custon-animate {
        outline: 0;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        background: var(--violet);
        width: 100%;
        min-width: 10px;
        max-height: 50px;
        min-height: 50px;
        border: 0;
        border-radius: 4px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        padding: 18px 0;
        color: var(--white);
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        overflow: hidden;
        cursor: pointer;
    }

    .btn-custon-animate:hover {
        opacity: 0.95;
    }

    .btn-custon-animate .animation {
        border-radius: 100%;
        animation: ripple 0.6s linear infinite;
    }
}
