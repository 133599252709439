@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.rewards-list {
    margin: auto 0;
    width: 100%;

    &__text {
        z-index: 1;
        color: var(--violet);
    }

    &__item {
        height: 40px;
        width: 70%;
        margin: 0 auto;
        @include flex(center, center);
        @include hexagon(100%, 47px, -46px, 3px, -47px, 8px);

        &:not(:last-child) {
            margin-bottom: 0.4rem;
        }

        &--current {
            color: var(--violet);

            @include hexagon-colored(var(--violet));
        }

        &--previous {
            color: $hexagon-border;
        }

        @media (max-width: 510px) {
            height: 32px;

            .top-hexagon::before,
            .bottom-hexagon::before {
                width: 70px;
            }

            .top-hexagon::before {
                left: -70px;
            }

            .bottom-hexagon::before {
                right: -70px;
            }
        }
    }
}
