* {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    background: #201f30;
    color: #ffffff;
    box-sizing: border-box;

    font-family: 'Nunito Sans', sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: 'wdth' 100, 'YTLC' 500;
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 20px;
}

.d-flex {
    display: flex;
}

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
            0 0 0 20px rgba(255, 255, 255, 0.1),
            0 0 0 40px rgba(255, 255, 255, 0.1),
            0 0 0 60px rgba(255, 255, 255, 0.1);
    }

    100% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1),
            0 0 0 40px rgba(255, 255, 255, 0.1),
            0 0 0 60px rgba(255, 255, 255, 0.1),
            0 0 0 80px rgba(255, 255, 255, 0);
    }
}

@media (max-width: 768px) {
    .container {
        padding: 0 16px;
    }
    .content {
        padding: 18px;
        margin: 18px 0;
    }
}

@media (max-width: 500px) {
    .container {
        padding: 0 12px;
    }
    .content {
        padding: 16px;
        margin: 16px 0;
    }
}

@media (max-width: 450px) {
    .container {
        padding: 0 10px;
    }
    .content {
        padding: 14px;
        margin: 14px 0;
    }
}

@media (max-width: 425px) {
    .container {
        padding: 0 8px;
    }
    .content {
        padding: 12px;
        margin: 12px 0;
    }
}

@media (max-width: 375px) {
    .container {
        padding: 0 6px;
    }
    .content {
        padding: 10px;
        margin: 10px 0;
    }
}

@media (max-width: 320px) {
    .container {
        padding: 0 4px;
    }
    .content {
        padding: 8px;
        margin: 8px 0;
    }
}
