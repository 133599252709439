.Leaderboard {
    margin-top: 24px;
    &-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-radius: 8px;
        background: var(--darkGrey);
        padding: 16px 10px;
        gap: 6px;

        &-icon {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            background: var(--violet);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            margin-right: 10px;
        }

        &-user {
            &-username {
                color: var(--white);
                font-weight: bold;
                font-size: 12px;
            }

            &-bonus {
                color: var(--violet);
                font-size: 14px;
            }
        }

        &-open {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            gap: 10px;
            p {
                font-size: 12px;
                width: fit-content;
            }

            span {
                width: fit-content;
                color: var(--violet);
                font-size: 26px;
            }
        }
    }

    &-list {
        h4 {
            text-align: center;
            margin: 10px 0;
            span {
                color: var(--violet);
            }
        }
        margin-bottom: 50px;
        @for $i from 1 through 10 {
            .Leaderboard-content:nth-child(#{$i}) {
                margin-bottom: 10px;
            }
        }
    }
}
