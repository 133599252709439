.Footer {
    position: fixed;
    z-index: 99999;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 60px;
    background: var(--darkBlue);
    border-top: 1px solid var(--violet);
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;

    &-wrapper {
        &-tabbar {
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .tabbar-list {
                color: #fff;
                cursor: pointer;
                font-size: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                min-width: 55px;
                position: relative;

                .badgeTab {
                    position: absolute;
                    content: '';
                    top: 0;
                    right: 0;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: red;
                }

                &.active {
                    color: var(--violet);
                }

                &:hover {
                    color: var(--violet);
                }

                span {
                    font-size: 14px;
                }
            }
        }
        // .ant-segmented {
        //     padding: unset !important;
        //     margin: unset !important;
        //     height: 50px;
        //     background-color: var(--darkBlueOpacity);

        //     .ant-segmented-group {
        //         height: 50px;
        //     }

        //     .ant-segmented-item-label {
        //         min-height: 50px !important;
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //         width: 150px;
        //     }

        //     .ant-segmented-item-selected {
        //         border-radius: 40px;
        //     }
        // }
        // .custom-segmented {
        //     border-radius: 25px;
        //     overflow: hidden;
        //     border: none !important;
        // }

        // .custom-segmented .ant-segmented-item {
        //     color: var(--white) !important;
        //     font-weight: bold;
        // }

        // .custom-segmented .ant-segmented-item-selected {
        //     background-color: var(--violet) !important;
        //     color: var(--white) !important;
        // }

        // .custom-segmented .ant-segmented-thumb {
        //     background-color: var(--violet) !important;
        //     border-radius: 25px;
        // }
    }
}
