.WelcomePage {
    width: 100%;
    overflow: hidden;

    &-wrapper {
        padding: 0 24px;
        min-height: 100vh;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-head {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            background: var(--violet);

            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 44px;
        }

        &-title {
            margin-bottom: 24px;

            h4 {
                font-size: 18px;
                text-align: center;
                color: var(--white);
                margin-bottom: 12px;
            }

            p {
                font-size: 14px;
                color: var(--white);
                line-height: 22px;
                text-align: center;
            }
        }

        &-action {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 16px;
        }
    }
}
