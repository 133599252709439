@import './styles/variables.scss';
@import './styles/mixins.scss';

.game {
    display: flex;
    height: 100%;
    background-color: $game-bg-color;

    &__inner {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 80px;
        height: 80%;
        margin: auto 0;

        @media (max-width: 1018px) {
            padding: 0 50px;
        }

        @media (max-width: 510px) {
            height: 90%;
        }
    }

    &__title {
        flex: 1;
        color: var(--violet);

        @media (max-width: 1018px) {
            flex: initial;
            margin: 32px auto;
        }
    }

    &__answers {
        max-width: 1886px;
        @include flex($wrap: wrap);

        @media (max-width: 1250px) {
            flex-direction: column;
            align-items: initial;
        }

        @media (max-width: 1018px) {
            align-items: center;
            color: #348584;
        }
    }

    &__overlay {
        @media (max-width: 1018px) {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            display: none;

            &--open {
                display: flex;
                z-index: 1;

                .game__rewards {
                    position: absolute;
                }
            }
        }
    }

    &__rewards {
        width: 376px;
        background-color: #fff;
        display: flex;
        height: 100%;
        right: 0;

        @media (max-width: 510px) {
            width: 100%;
        }
    }
}
