@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.answer-btn {
    flex-basis: 35%;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    height: 72px;
    background-color: inherit;
    max-width: 557px;

    &:nth-child(odd) {
        margin-right: 5.4rem;
    }

    @include hexagon(110%, 18px, -17px, 6px, -16px, 20px);

    @media (min-width: 1250px) {
        &:hover {
            @include hexagon-colored($accent-color);
        }
    }

    &__letter {
        color: $accent-color;
        z-index: 1;
    }

    &__text {
        display: inline-block;
        margin-left: 0.5rem;
        text-transform: capitalize;
        text-align: left;
        z-index: 1;
    }

    &--selected {
        @include hexagon-colored($accent-color, $selected-answer-color);

        &:hover {
            @include hexagon-colored($accent-color, $selected-answer-color);
        }
    }

    &--correct {
        @include hexagon-colored(
            $hexagon-correct-border,
            $correct-answer-color
        );

        &:hover {
            @include hexagon-colored(
                $hexagon-correct-border,
                $correct-answer-color
            );
        }
    }

    &--wrong {
        @include hexagon-colored($hexagon-wrong-border, $wrong-answer-color);

        &:hover {
            @include hexagon-colored(
                $hexagon-wrong-border,
                $wrong-answer-color
            );
        }
    }

    @media (max-width: 1870px) {
        .top-hexagon,
        .bottom-hexagon {
            width: 111%;
        }
    }

    @media (max-width: 1800px) {
        .top-hexagon,
        .bottom-hexagon {
            width: 112%;
        }
    }

    @media (max-width: 1710px) {
        .top-hexagon,
        .bottom-hexagon {
            width: 113%;
        }
    }

    @media (max-width: 1640px) {
        .top-hexagon,
        .bottom-hexagon {
            width: 114%;
        }
    }

    @media (max-width: 1570px) {
        .top-hexagon,
        .bottom-hexagon {
            width: 116%;
        }

        .top-hexagon {
            transform: perspective(15px) rotateX(3deg);

            &::before {
                left: -16px;
            }
        }

        .bottom-hexagon {
            transform: perspective(15px) rotateX(-3deg);
        }
    }

    @media (max-width: 1380px) {
        .top-hexagon,
        .bottom-hexagon {
            width: 118%;
        }
    }

    @media (max-width: 1315px) {
        .top-hexagon,
        .bottom-hexagon {
            width: 120%;
        }
    }

    @media (max-width: 1250px) {
        flex-basis: inherit;
        width: 70%;

        &:nth-child(odd) {
            margin-right: 0rem;
        }
    }

    @media (max-width: 1018px) {
        .top-hexagon,
        .bottom-hexagon {
            width: 100%;
        }
    }

    @media (max-width: 830px) {
        .top-hexagon {
            transform: perspective(12px) rotateX(3deg);
        }

        .bottom-hexagon {
            transform: perspective(12px) rotateX(-3deg);
        }
    }

    @media (max-width: 510px) {
        height: 56px;
        width: 100%;

        .top-hexagon::before,
        .bottom-hexagon::before {
            width: 26px;
            height: 1px;
        }

        .top-hexagon {
            transform: perspective(10px) rotateX(3deg);

            &::before {
                left: -24px;
            }
        }

        .bottom-hexagon {
            transform: perspective(10px) rotateX(-3deg);

            &::before {
                right: -24px;
            }
        }
    }
}
