.Header {
    width: 100%;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    background-color: var(--darkBlueOpacity);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 24px;
    z-index: 99 !important;

    // box-shadow: 0 -2px 10px var(--violet);
    border-bottom: 1px solid var(--violet);

    &.active {
        position: fixed;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        box-shadow: 0 0px 40px var(--black);
    }

    &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        padding: 0 24px;

        &-profit {
            padding: 4px 24px;
            border-top: 1px solid var(--violet);
            border-radius: 8px;
            display: flex;
            align-items: center;
            gap: 10px;

            .profit {
                display: flex;
                align-items: center;
                gap: 10px;

                font-size: 18px;

                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        &-username {
            display: flex;
            align-items: center;
            gap: 12px;

            .avatar-i {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background: red;
                padding: 6px;
            }
        }
    }
}
