$font: 'Inter', sans-serif;
$text-color: #1C1C21;
$accent-color: #FF8B37;
$hover-color: #FFAC70;
$active-color: #E87928;
$game-bg-color: #F5F5F7;
$hexagon-border: #D0D0D8;
$selected-answer-color: #FFF3EB;
$correct-answer-color: #E6FAEA;
$hexagon-correct-border: #47D867;
$wrong-answer-color: #FDEEED;
$hexagon-wrong-border: #EC6259;


