.TelegramChannelRedirectPage {
    width: 100%;
    &-wrapper {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h1 {
            font-size: 28px;
            color: var(--white);
        }

        .tg-qr-code {
            height: 220px;
            margin-top: 24px;
        }

        a {
            text-decoration: none;
            color: var(--white);
        }
    }
}
