.HomePage {
    &-wrapper {
        padding-bottom: 460px;
    }

    .welcome-user {
        margin-bottom: 12px;
        border: 1px solid var(--violet);
        border-radius: 16px;
        padding: 10px;

        display: flex;
        align-items: center;
        flex-direction: column;

        &-head {
            width: 120px;
            border-radius: 50%;

            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 12px;

            img {
                max-height: 120px;
                border-radius: 50%;
            }
        }

        h1 {
            font-size: 24px;
            margin-bottom: 12px;
            text-align: center;
        }

        span {
            font-size: 18px;
            text-align: center;
        }

        p {
            font-size: 14px;
            margin-top: 8px;
        }

        .btn-start {
            border: 1px solid var(--violet);
            height: 45px;
            margin-top: 12px;
            border-radius: 16px;
        }
    }
}
