.IBottomDrawer {
    .ant-drawer {
        .ant-drawer-content {
            border-radius: 26px !important;
            box-shadow: 0 12px 28px var(--violet);
            border-top: 1px solid var(--violet);
        }
    }
    &-content {
        &-head {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 16px;

            img {
                width: auto;
                height: 140px;
            }
        }

        &-text {
            text-align: center;
            margin-bottom: 16px;
            color: var(--white);

            .app-code {
                color: var(--violet);
                font-size: 16px;
                font-weight: bold;
            }

            p {
                text-align: center;
            }
        }
    }
}
