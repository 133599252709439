@import '../../styles/variables.scss';

.btn-wrapper {
    display: none;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    right: 5px;
    top: 5px;

    @media (max-width: 1018px) {
        display: flex;
    }
}

.btn {
    width: 26px;
    height: 25px;
    position: relative;
    cursor: pointer;
}

.btn span {
    width: 100%;
    height: 3px;
    position: absolute;
    background-color: $text-color;
    border-radius: 5px;
    transition: 0.5s;
    left: 0;
}

.btn span:nth-child(1) {
    top: 0;
    transform-origin: left center;
}

.btn span:nth-child(2) {
    top: 10px;
}

.btn span:nth-child(3) {
    top: 20px;
    transform-origin: left center;
}

.btn.open span:nth-child(1) {
    transform: rotate(45deg);
    left: 8px;
}

.btn.open span:nth-child(2) {
    opacity: 0;
    width: 0;
}

.btn.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 19px;
    left: 8px;
}
