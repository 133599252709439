.WinnersPage {
    &-wrapper {
        padding-bottom: 460px;
        position: relative;

        &-head {
            margin-top: -44px;
            width: 100%;
            height: 260px;
            background: url('../../assets/images/image-enhanced.png');
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            position: relative;
            z-index: -1;

            .user1 {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -86px);
                width: 120px;
                height: 120px;
                border: 2px solid orange;
                border-radius: 50%;
                box-shadow: 0 2px 16px orange;
                background: #000;
                z-index: 3;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    &:first-child {
                        position: absolute;
                        content: '';
                        bottom: 10px;
                    }
                }
            }

            .user2 {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(15%, -70px);
                width: 120px;
                height: 120px;
                border: 2px solid rgb(3, 110, 168);
                border-radius: 50%;
                box-shadow: 0 2px 16px rgb(3, 110, 168);
                background: #000;
                z-index: 2;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    &:first-child {
                        position: absolute;
                        content: '';
                        bottom: 10px;
                    }
                }
            }

            .user3 {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(-15%, -70px);
                width: 120px;
                height: 120px;
                border: 2px solid rgb(188, 3, 188);
                border-radius: 50%;
                box-shadow: 0 2px 16px rgb(188, 3, 188);
                background: #000;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    &:first-child {
                        position: absolute;
                        content: '';
                        bottom: 10px;
                    }
                }
            }
        }
        .hr-win {
            position: absolute;
            content: '';
            top: 180px;
            width: 100%;
            height: 80px;
            background: linear-gradient(to top, #000, #ffffff00);

            &::before {
                position: absolute;
                content: '';
                top: 80px;
                width: 100%;
                height: 120px;
                background: linear-gradient(to bottom, #000, #ffffff00);
            }
        }
        .winners {
            margin-top: 70px;

            h2 {
                margin: 24px 0;
                font-size: 24px;
                font-weight: normal;
            }

            ul {
                margin: 0;
                padding: 0;

                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;
                    border: 1px solid var(--violet);
                    padding: 16px 12px;
                    border-radius: 12px;
                }
            }
        }
    }
}
